import React, { FormEvent, useEffect } from "react";
import QRCode from "qrcode.react";
import { useLocation } from "@reach/router";
import { checkMembershipCard, deleteCardCookie } from "store/actions/virtual-card.action";
import { VirtualCardReducerInterface } from "store/reducers/virtual-card.reducer";
import { Helmet } from "react-helmet";
import { translationHelper } from "helpers/translation.helper";
import { useConfig } from "hooks/use-config.hook";
import { CustomerMembershipCardInterface } from "interfaces/customer-membership-card.interface";
import TextInput from "components/utils/TextInput";
import Button from "components/utils/button.component";
import { getCookie } from "helpers/cookie.helper";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks/use-redux.hook";

const Card: React.FC<{
    customer_membership_card: CustomerMembershipCardInterface;
}> = (props) => {
    const { customer_membership_card } = props;

    const { i18n } = useTranslation();

    const location = useLocation();

    const logout = () => {
        const urlParams = new URLSearchParams(location.search);
        const id = urlParams.get("id");
        if (id) {
            deleteCardCookie(id);
        }

        window.location.reload();
    };

    const seat =
        customer_membership_card.seats.length > 0
            ? customer_membership_card.seats[0]
            : null;

    return (
        <>
            <img
                src={customer_membership_card.membership_card_type.header_image}
                alt=""
            />
            <div className="w-full flex justify-center text-center">
                <div className="block my-4">
                    {customer_membership_card.customer && (
                        <>
                            <h5 className="text-xl">
                                {
                                    customer_membership_card.customer.person
                                        .first_name
                                }{" "}
                                {
                                    customer_membership_card.customer.person
                                        .last_name
                                }
                            </h5>
                            <h5 className="text-xl mb-2">
                                {
                                    customer_membership_card.customer.person
                                        .company_name
                                }
                            </h5>
                        </>
                    )}
                    <div className="text-center">
                        <QRCode
                            renderAs="canvas"
                            value={`http://e-t.io/?t=cc0&d=${customer_membership_card.id}&m=mob`}
                            fgColor="#2C2E35"
                            includeMargin={false}
                            size={128}
                            className="m-auto mb-2"
                        />
                        <h5 className="text-xl">
                            {customer_membership_card.human_readable_code}
                            {seat && seat.section && (
                                <div>
                                    Katsomo:{" "}
                                    {translationHelper(
                                        seat.section.title,
                                        i18n.language,
                                    )}
                                </div>
                            )}
                            {seat && seat.row && <div>Rivi: {seat.row}</div>}
                            {seat && seat.seat && (
                                <div>Paikka: {seat.seat}</div>
                            )}
                        </h5>
                    </div>

                    <div className="mt-20">
                        <Button
                            color="primary"
                            type="button"
                            onClick={() => logout()}
                        >
                            Kirjaudu ulos
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

const PINForm: React.FC = (props) => {
    const [pin, setPin] = React.useState("");

    const config = useConfig();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const authentication_failed = useAppSelector(
        state => state.virtual_card.authentication_failed,
    );

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        const urlParams = new URLSearchParams(location.search);
        dispatch(checkMembershipCard(urlParams.get("id") || "", pin));
    };

    return (
        <div className="flex flex-col min-h-screen max-w-md justify-center items-center">
            {config.theme.header_img && (
                <img src={config.theme.header_img} alt="" />
            )}
            <form onSubmit={handleSubmit}>
                <div className="mb-2">
                    <TextInput
                        label="PIN-koodi"
                        onChange={(event) => setPin(event.target.value)}
                        value={pin}
                    />
                </div>
                {authentication_failed && (
                    <p className="mb-2 text-sm text-red-600" id="pin-error">
                        PIN-koodi on virheellinen.
                    </p>
                )}
                <Button
                    color="primary"
                    type="submit"
                    label="Avaa kortti"
                    fullWidth={true}
                />
            </form>
        </div>
    );
};

const Header: React.FC = (props) => {
    const config = useConfig();

    const location = useLocation();

    return (
        <div>
            <GatsbySeo
                nofollow={true}
                title={`Kausikortti - ${translationHelper(config.title, "fi")}`}
                description={
                    "Avaa moobilikausikorttisi omalla älypuhelimellasi."
                }
                openGraph={{
                    url: location && location.href,
                    title: `Kausikortti - ${translationHelper(
                        config.title,
                        "fi",
                    )}`,
                    description:
                        "Avaa moobilikausikorttisi omalla älypuhelimellasi.",
                    images: config.theme.header_img
                        ? [{ url: config.theme.header_img }]
                        : [],
                    type: "og:website",
                }}
                twitter={{
                    handle: "@handle",
                    site: "@site",
                    cardType: "summary_large_image",
                }}
            />
            <Helmet>
                <title>
                    Kausikortti - {translationHelper(config.title, "fi")}
                </title>
                <link
                    rel="icon"
                    sizes="48x48"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-48x48.png`}
                />
                <link
                    rel="icon"
                    sizes="72x72"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-72x72.png`}
                />
                <link
                    rel="icon"
                    sizes="96x96"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-96x96.png`}
                />
                <link
                    rel="icon"
                    sizes="144x144"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-144x144.png`}
                />
                <link
                    rel="icon"
                    sizes="192x192"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-192x192.png`}
                />
                <link
                    rel="icon"
                    sizes="256x256"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-256x256.png`}
                />
                <link
                    rel="icon"
                    sizes="384x384"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-384x384.png`}
                />
                <link
                    rel="icon"
                    sizes="512x512"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-512x512.png`}
                />
            </Helmet>
        </div>
    );
};

const VirtualCard: React.FC = (props) => {
    const card_reducer: VirtualCardReducerInterface = useAppSelector(
        state => state.virtual_card,
    );

    const [isLoading, setLoading] = React.useState(true);

    const location = useLocation();

    const dispatch = useAppDispatch();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);

        const id = urlParams.get("id");
        let pin = null;

        if (id) {
            pin = getCookie(`customer_membership_${id}`);
        }

        if (pin && id) {
            dispatch(checkMembershipCard(id, pin));
        }

        setLoading(false);
    }, [dispatch, location.search]);

    const logout = () => {
        const urlParams = new URLSearchParams(location.search);
        const id = urlParams.get("id");
        if (id) {
            deleteCardCookie(id);
        }

        window.location.reload();
    };

    const { customer_membership_card } = card_reducer;

    if (isLoading) {
        return (
            <>
                <Header />
                <div className="flex flex-col min-h-screen max-w-md">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        className="animate-spin-slow"
                    >
                        <path
                            stroke="#000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M20 4v5h-.582m0 0a8.001 8.001 0 00-15.356 2m15.356-2H15M4 20v-5h.581m0 0a8.003 8.003 0 0015.357-2M4.581 15H9"
                        />
                    </svg>
                </div>
            </>
        );
    }

    if (card_reducer.isLoaded && card_reducer.isEmpty) {
        return (
            <>
                <Header />
                <div className="flex flex-col min-h-screen max-w-md justify-center items-center">
                    <h1 className="text-3xl">Viallinen kausikorttitunniste</h1>
                    <div className="mt-20">
                        <Button
                            color="primary"
                            type="button"
                            onClick={() => logout()}
                        >
                            Kirjaudu ulos
                        </Button>
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className="flex flex-col min-h-screen max-w-md">
            <Header />
            {card_reducer.requires_authentication && <PINForm />}
            {card_reducer.isLoaded &&
            !card_reducer.isEmpty &&
            customer_membership_card && (
                <Card customer_membership_card={customer_membership_card} />
            )}
        </div>
    );
};

export default VirtualCard;
